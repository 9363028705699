<template>
    <div class="page">
        <div class="card cast" v-for="character in staff" :key="character.name">
            <h2>{{character.name}}</h2>
            <table>
                <tr v-for="person in character.people" :key="person.name">
                    <td>{{person.language}}</td>
                    <td>{{person.name}}</td>
                    <td><img :src="person.image" :alt="'Image of ' +person.name" /></td>
                </tr>
            </table>
            <a v-for="u in character.source" :key="u" :href="u">Source</a>
        </div>
    </div>
</template>
<script>
import staff from '@/data/cast.json'

export default {
    name: 'staff',
    components: {},
    data: function () {
        return {
            staff: staff
        }
    }
}
</script>
<style>
.cast table {
    padding: 0;
}
.cast table tr {
    float: left;
    margin: 10px;
    overflow-x: auto;
}
.cast table th, .cast table td {
    text-align: center;
    display: block;
    padding: 0;
    margin: 0;
}
.cast table td img {
    max-width: 100px;
    width: 100%;
    object-fit: cover;
    height: 130px;
    display: block;
    margin: 0 auto;
}
.cast a {
    margin-left: 5px;
}
 
</style>